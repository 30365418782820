<template>
  <div class="color-picker">
    <div class="hex-colors__label">Add a color in Hex or choose below</div>
    <v-combobox
      v-if="isShowColorsCustomInput"
      class="hex-colors"
      :delimiters="[',', ' ', ';']"
      v-model="colorsListComputed"
      multiple
      ref="smb-color-picker"
      small-chips
      solo
    >
      <template v-slot:selection="{ attrs, item, parent, index }">
        <div class="hex-color-item">
          <div
            v-if="isColorCanEditOrDelete(item, index)"
            class="icons-container"
          >
            <v-icon small @click="editColor(item, index)"> $edit </v-icon>
            <v-icon small @click="deleteHandler(index)"> $delete </v-icon>
          </div>
          <div class="color-info">
            <span class="color-info__name">{{ item }}</span>
            <v-chip
              v-bind="attrs"
              :color="item"
              label
              small
              :style="{ border: '1px solid black' }"
            >
              <span :style="{ color: item }" class="pr-2"> </span>
            </v-chip>
          </div>
        </div>
      </template>
    </v-combobox>
    <div v-if="isShowColorsLimit" class="count-of-colors">
      {{ colorsListComputed.length }} / {{ limit }}
    </div>
    <v-color-picker
      v-model="currentColorComputed"
      dot-size="10"
      swatches-max-height="200"
      mode="hexa"
      @update:color="updateColorHandler"
    ></v-color-picker>
    <div class="color-picker__buttons" v-if="isShowButtonsSection">
      <v-btn
        :disabled="!currentIndexComputed && colorsListComputed.length === limit"
        @click="selectColorHandler"
      >
        {{ currentIndexComputed ? "Change" : "Add" }} a color</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "SmbColorPicker",
  props: {
    colorsList: {
      type: Array,
      default: () => ["#000000", "#FFFFFF"],
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
    currentColor: {
      type: [String, Object],
      default: null,
    },
    triggeredAddColorButton: {
      type: [Date, Object],
      default: null,
    },
    isShowButtonsSection: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 6,
    },
    isShowColorsCustomInput: {
      type: Boolean,
      default: true,
    },
    isShowColorsLimit: {
      type: Boolean,
      default: true,
    },
    isCanDeleteDefaultColors: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    triggeredAddColorButton(newValue) {
      if (newValue) {
        this.selectColorHandler();
      }
    },
  },
  computed: {
    colorsListComputed: {
      get() {
        return JSON.parse(JSON.stringify(this.colorsList));
      },
      set(newColorsList) {
        if (newColorsList) {
          this.$emit("colorListChanged", this.validateValues(newColorsList));
        }
      },
    },
    currentIndexComputed: {
      get() {
        return this.currentIndex;
      },
      set(newCurrentIndex) {
        this.$emit("currentIndexChanged", newCurrentIndex);
      },
    },
    currentColorComputed: {
      get() {
        return this.currentColor;
      },
      set(newCurrentColor) {
        this.$emit("currentColorChanged", newCurrentColor);
      },
    },
  },
  methods: {
    validateValues(valueForValidate) {
      if (valueForValidate) {
        return valueForValidate.filter(
          (color, index) => this.isHexColor(color) || index <= this.limit - 1
        );
      }
    },
    isHexColor(str) {
      return /^#?([0-9A-F]{3}|[0-9A-F]{6})$/i.test(str);
    },
    isColorCanEditOrDelete(color) {
      let notAllowedColorsForEditOrDelete = this.isCanDeleteDefaultColors
        ? []
        : ["#000000", "#FFFFFF"];
      return !notAllowedColorsForEditOrDelete.includes(color);
    },
    editColor(color, index) {
      this.$refs["smb-color-picker"].blur();
      this.currentColorComputed = color;
      this.currentIndexComputed = index;
    },
    deleteHandler(index) {
      this.$refs["smb-color-picker"].blur();

      this.colorsListComputed = this.colorsListComputed.filter(
        (color, colorIndex) => index !== colorIndex
      );
      this.currentIndexComputed = null;
    },
    updateColorHandler({ hex }) {
      this.currentColorComputed = hex;
    },
    selectColorHandler() {
      let newColor =
        this.currentColorComputed && this.currentColorComputed.hex
          ? this.currentColorComputed.hex
          : this.currentColorComputed;
      if (!this.currentIndexComputed) {
        this.colorsListComputed = [...this.colorsListComputed, ...[newColor]];
      } else {
        this.colorsListComputed = this.colorsListComputed.with(
          this.currentIndexComputed,
          newColor
        );

        this.currentIndexComputed = null;
      }
      this.validateValues();
    },
  },
};
</script>

<style scoped lang="scss">
.color-picker {
  padding: 21px 20px 10px 20px;
  width: 100%;
  max-width: 600px;
  ::v-deep {
    .v-input__slot {
      box-shadow: none !important;
      border: 1px solid #c7c9ca;
      border-radius: 4px;
    }
    .v-color-picker__edit {
      .v-btn {
        display: none;
      }
    }
    .v-input__append-inner {
      display: none;
    }
    .v-select__selections {
      align-items: normal;
    }
    .v-color-picker {
      margin: 0 auto;
    }
    .v-color-picker__alpha {
      display: none;
    }
    .v-color-picker__hue {
      margin-bottom: 0;
    }
    .v-color-picker__hue:not(.v-input--is-disabled) {
      background: linear-gradient(
        90deg,
        red,
        #ff0 16.66%,
        #0f0 33.33%,
        #0ff 50%,
        #00f 66.66%,
        #f0f 83.33%,
        red
      );
    }
    .v-color-picker__sliders {
      margin-left: 30px;
    }
    .v-color-picker__preview
      .v-slider:not(.v-slider--disabled)
      .v-slider__thumb {
      background: rgba(0, 0, 0, 0.56);
    }
    .v-text-field__details {
      display: none;
    }
  }
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    .v-btn {
      text-indent: inherit;
      letter-spacing: inherit;
      text-transform: none;
      margin: 10px;
      height: 40px;
      padding: 0 30px;
      background: transparent;
      box-shadow: none;
      font-weight: 600;
      font-size: 16px;
      text-align: right;
      border-radius: 25px;
      border: 1px solid rgb(29, 29, 27);
    }
  }
  .hex-color-item {
    border: 1px solid;
    border-radius: 10px;
    padding: 5px;
    margin: 5px;
    display: inline-flex;
    flex-direction: column;
    .icons-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        margin-left: 10px;
      }
    }
    .color-info {
      margin-top: auto;
      display: inline-flex;
      align-items: center;
      &__name {
        font-size: 14px;
      }
      .v-chip--label {
        width: 18px;
        height: 18px;
        padding: 0 !important;
        margin: 2px 0 0 4px;
      }
    }
  }
  .count-of-colors {
    text-align: end;
    padding-bottom: 10px;
  }
  .hex-colors {
    &__label {
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
    }
  }
}
</style>
