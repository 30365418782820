<template>
  <div class="set-logo">
    <div class="set-logo__group" v-if="businessAssets.length">
      <div class="set-logo__top">
        <div class="checkbox-container">
          <input
            type="radio"
            v-model="logoType"
            @change="selectBusinessAssetsList()"
            value="1"
            id="1"
            name="logo"
          />
          <label for="1" class="checkbox-container__label"
            ><span class="text">Use this logo</span>
          </label>
        </div>
        <div class="set-logo__list">
          <div
            class="set-logo__list-item"
            @click="setAssetsLogo(item)"
            :class="{ active: item === attachedLogo }"
            v-for="(item, index) in businessAssets"
            :key="index"
          >
            <img :src="item" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="set-logo__group">
      <div class="set-logo__bottom">
        <div class="checkbox-container">
          <input
            type="radio"
            id="2"
            v-model="logoType"
            value="2"
            @change="$emit('logoType', logoType)"
            name="logo"
          />
          <label for="2" class="checkbox-container__label"> </label>
        </div>
        <div class="upload-container">
          <Loader v-if="isLoading" />
          <SmbFileUploader
            :filesProp="attachedImage"
            cutomClass="mb-uploader"
            appendIcon="mdi-upload"
            :label="'Upload'"
            acceptProp="image/*"
            @filesWasUpdated="uploadSelected($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmbFileUploader from "@/components/smb-client/SmbFileUploader";
import uploader from "@/mixins/uploader";
import Loader from "@/components/global/Loader";
import { mapGetters } from "vuex";
export default {
  name: "LoadingLogo",
  props: {
    attachedLogo: {
      type: String,
      default: "",
    },
    isUpload: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    attachedImage: null,
    isLoading: null,
    businessAssets: [],
    logoType: null,
  }),
  mixins: [uploader],
  watch: {
    isUpload(val) {
      if (val) {
        this.startUpload();
      }
    },
  },
  created() {
    this.businessAssets = [...this.getCurrentSmbCustomer.business_assets].slice(
      0,
      3
    );
  },
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
  },
  components: { SmbFileUploader, Loader },
  methods: {
    uploadSelected(file) {
      file ? (this.attachedImage = file) : (this.attachedImage = null);
      this.$emit("fileAttached", file);
      this.logoType = "2";
      this.$emit("logoType", this.logoType);
    },
    setAssetsLogo(item) {
      this.logoType = "1";
      this.selectBusinessAssetsList();
      this.$emit("setNewLogo", item);
    },
    async startUpload() {
      this.isLoading = true;
      const {
        url,
        fields: { key },
      } = await this.uploadFileHandler(
        [...[this.attachedImage]],
        this.getCurrentSmbCustomer.id
      );
      this.$emit("newLogo", url + key);
      this.attachedImage = null;
      this.isLoading = false;
    },
    selectBusinessAssetsList() {
      this.$emit("logoType", this.logoType);
      if (!this.attachedLogo) {
        this.$emit("setNewLogo", this.businessAssets[0]);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.set-logo {
  display: flex;
  flex-direction: column;
  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 48px;
    margin-left: calc(50% - 250px);
    margin-right: auto;
    min-width: 500px;
    .checkbox-container {
      margin-right: 19px !important;
      @media screen and (max-width: 500px) {
        margin-right: 0;
      }
      .text {
        font-size: 19px;
        font-weight: 500;
        margin-left: 15px;
        @media screen and (max-width: 820px) {
          font-size: 14px;
        }
      }
    }
  }
  &__group {
    display: flex;
    align-items: center;
  }
  &__bottom {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    .upload-container {
      width: 100%;
    }
    .checkbox-container {
      margin-right: 20px;
      flex-shrink: 0;
      &__label {
        margin-top: 15px;
        display: block;
      }
    }
  }
  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 140px;
    @media screen and (max-width: 1300px) {
      margin-left: 80px;
    }
    @media screen and (max-width: 820px) {
      margin-left: 5px;
    }
    &-item {
      width: 182px;
      height: 182px;
      @media screen and (max-width: 1300px) {
        width: 120px;
        height: 120px;
      }
      @media screen and (max-width: 820px) {
        width: 65px;
        height: 65px;
      }
      border-radius: 13px;
      border: 1px solid #000;
      overflow: hidden;
      margin-right: 10px;
      cursor: pointer;
      background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
        linear-gradient(135deg, #ccc 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, #ccc 75%),
        linear-gradient(135deg, transparent 75%, #ccc 75%);
      background-size: 16px 16px; /* Must be a square */
      background-position: 0 0, 8px 0, 8px -8px, 0px 8px;
      background-repeat: initial;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        border: none;
        box-shadow: 0 0 0 3px #92c4ff;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .checkbox-container {
    position: relative;
    padding-left: 24px;
    flex-shrink: 0;
    input[type="radio"] {
      position: absolute;
      left: -999999;
      opacity: 0;
      width: 0;
      height: 0;
      & + label {
        display: block;
        position: relative;
        min-height: 24px;
      }
      &:not(:checked),
      &:checked {
        & + label {
          &:before {
            content: "";
            position: absolute;
            left: -24px;
            top: 0;
            width: 24px;
            height: 24px;
            border: 1px solid #1d1d1b;
            background: #fff;
            z-index: 100;
            border-radius: 4px;
          }
        }
      }
      &:checked {
        & + label {
          &:after {
            content: "";
            position: absolute;
            left: -17px;
            top: 7px;
            width: 10px;
            height: 10px;
            background: #92c4ff;
            z-index: 100;
            border-radius: 2px;
          }
        }
      }
    }
  }
}
.attached-logo {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.uploader-wrapper {
  position: relative;
}
</style>
