<template>
  <div class="location">
    <div class="row-wrapper" v-for="item of addressList" :key="item.id">
      <SmbInput
        :valueProp="item.val"
        placeholder="Example: Upper East Side, New York City, NY. "
        @propWasUpdated="editData($event, item.id)"
      />
      <span
        v-if="addressList.length > 1"
        @click="removeAddress(item.id)"
        class="remove-row"
      ></span>
    </div>
    <div class="add-more-wrapper">
      <button class="new-row" @click="newAddress()">Add more</button>
    </div>
  </div>
</template>

<script>
import SmbInput from "@/components/smb-client/SmbInput.vue";

export default {
  name: "Location",
  props: {
    area: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    addressList: [],
    stringVal: "",
  }),
  components: { SmbInput },
  created() {
    this.formattedData();
    window.addEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        this.newAddress();
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        this.newAddress();
      }
    });
  },
  methods: {
    dataToString() {
      this.stringVal = "";
      this.addressList.forEach((item) => {
        if (item.val.length) {
          this.stringVal = this.stringVal + item.val + "; ";
        }
      });
      this.stringVal = this.stringVal.toString().trimEnd().replace(/;+$/, "");
      this.$emit("propWasUpdated", this.stringVal);
    },
    newAddress() {
      this.addressList.push({
        id: this.addressList[this.addressList.length - 1].id + 1,
        val: "",
      });
    },
    removeAddress(id) {
      this.addressList = this.addressList.filter((i) => i.id !== id);
      this.dataToString();
    },
    formattedData() {
      this.area.split(";").forEach((item, index) => {
        this.addressList.push({
          id: index,
          val: item.trimStart(),
        });
      });
    },
    editData(val, id) {
      let index = this.addressList.findIndex((i) => i.id === id);
      this.addressList[index].val = val;
      this.dataToString();
    },
  },
};
</script>

<style scoped lang="scss">
.row-wrapper {
  position: relative;
  ::v-deep {
    .v-text-field__details {
      display: none;
    }
    .v-input__slot {
      padding-right: 50px !important;
    }
  }
  .remove-row {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
    background: #000;
    border-radius: 50%;
    cursor: pointer;
    &:before {
      content: "";
      display: block;
      width: 10px;
      height: 2px;
      background: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.add-more-wrapper {
  display: flex;
  justify-content: flex-end;
  .new-row {
    display: inline-flex;
    padding: 7px 10px;
    background: #000;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
  }
}
</style>
