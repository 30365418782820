<template>
  <div class="target-customers">
    <div
      v-for="targetCustomer in targetCustomersList"
      :key="targetCustomer"
      class="target-customers__item"
    >
      <v-btn
        class="target-customers__btn"
        :class="{ active: selectedTargetCustomers.includes(targetCustomer) }"
        @click="$emit('clickedOnTargetCustomer', targetCustomer)"
        >{{ targetCustomer }}</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "TargetCustomers",
  props: {
    targetCustomersList: {
      type: Array,
      default: () => [],
    },
    selectedTargetCustomers: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.target-customers {
  width: 100%;
  padding-top: 20px;
  max-width: 860px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  @media screen and (max-width: 920px) {
    max-width: 315px;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 12px;
  }
  &__btn {
    background: #92c4ff !important;
    border-radius: 6px;
    height: 69px !important;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: #1d1d1b;
    width: 100%;
    text-transform: none;
    letter-spacing: inherit;
    text-align: left !important;
    justify-content: flex-start;
    box-shadow: none;
    @media screen and (max-width: 920px) {
      font-size: 14px;
      height: 50px !important;
    }
    &.active {
      font-weight: 700;
      background: #f5ede2 !important;
    }
  }
}
</style>
