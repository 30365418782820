var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['color-slider', { brokerages: _vm.isBrokerages }]},[(_vm.presets.length)?_c('VueSlickCarousel',_vm._b({ref:"carousel",scopedSlots:_vm._u([{key:"nextArrow",fn:function(){return [_c('div',{staticClass:"custom-arrow"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"11","height":"16","viewBox":"0 0 11 16","fill":"none"}},[_c('path',{attrs:{"d":"M2.17972 0.316623L10.7212 7.40897C10.8226 7.4934 10.8942 7.58487 10.9361 7.68338C10.9787 7.78188 11 7.88742 11 8C11 8.11258 10.9787 8.21812 10.9361 8.31662C10.8942 8.41513 10.8226 8.5066 10.7212 8.59103L2.17972 15.7045C1.94316 15.9015 1.64747 16 1.29263 16C0.937788 16 0.63364 15.8945 0.380184 15.6834C0.126728 15.4723 0 15.226 0 14.9446C0 14.6631 0.126728 14.4169 0.380184 14.2058L7.8318 8L0.380184 1.7942C0.143625 1.59719 0.0253458 1.35458 0.0253458 1.06639C0.0253458 0.777626 0.152074 0.527704 0.40553 0.316623C0.658986 0.105541 0.954685 0 1.29263 0C1.63057 0 1.92627 0.105541 2.17972 0.316623Z","fill":"#585757"}})])])]},proxy:true}],null,false,1098913253)},'VueSlickCarousel',_vm.sliderSettings,false),_vm._l((_vm.presets),function(item){return _c('div',{key:item.id,staticClass:"color-slider__item"},[_c('div',{class:[
          'color-slider__item-wrapper',
          {
            active:
              _vm.selectedColorSetId !== null && _vm.selectedColorSetId === item.id,
          },
          {
            'no-active':
              _vm.selectedColorSetId !== null && _vm.selectedColorSetId !== item.id,
          },
        ]},_vm._l((item.colors),function(color,index){return _c('div',{key:index,staticClass:"color-slider__item-color",style:({ backgroundColor: color }),on:{"click":function($event){return _vm.setColorPreset(item)}}})}),0)])}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }