<template>
  <div>
    <div class="names">
      <SmbInput
        class="names__first-name"
        :valueProp="firstNameModel"
        placeholder="First name"
        @propWasUpdated="firstNameModel = $event"
        :rules="[rules.required]"
      />
      <SmbInput
        :valueProp="lastNameModel"
        placeholder="Last name"
        @propWasUpdated="lastNameModel = $event"
        :rules="[rules.required]"
      />
    </div>
    <div class="location">
      <div class="row-wrapper">
        <SmbInput
          :valueProp="emailModel"
          placeholder="Email    (example: john@real-estate.com)"
          @propWasUpdated="emailModel = $event"
          :rules="[
            rules.required,
            rules.email,
            rules.isEmailHasSpecificDomainName,
          ]"
        />
      </div>
    </div>
    <div class="location">
      <div class="row-wrapper">
        <SmbInput
          :valueProp="phoneModel"
          placeholder="Mobile phone    example (646) 555-1234"
          @propWasUpdated="phoneModel = $event"
          type="number"
          :rules="[rules.required, rules.phone, rules.phoneLength]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import formValidationMixin from "@/shared/validationRules";

import SmbInput from "@/components/smb-client/SmbInput.vue";

export default {
  name: "BaseForm",
  mixins: [formValidationMixin],
  components: { SmbInput },
  props: {
    firstName: {
      type: String,
      default: "",
    },
    lastName: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    phone: {
      type: String,
      default: "",
    },
  },
  computed: {
    emailModel: {
      get() {
        return this.email;
      },
      set(value) {
        this.$emit("emailWasUpdated", value);
      },
    },
    phoneModel: {
      get() {
        return this.phone;
      },
      set(value) {
        this.$emit("phoneWasUpdated", value);
      },
    },
    firstNameModel: {
      get() {
        return this.firstName;
      },
      set(value) {
        this.$emit("firstNameWasUpdated", value);
      },
    },
    lastNameModel: {
      get() {
        return this.lastName;
      },
      set(value) {
        this.$emit("lastNameWasUpdated", value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.names {
  display: flex;
  &__first-name {
    padding-right: 10px;
  }
}
.row-wrapper {
  position: relative;
  ::v-deep {
    .v-input__slot {
      padding-right: 50px !important;
    }
  }
}
</style>
