<template>
  <SmbInput
    :valueProp="zillowLinkModel"
    :errorMessages="errorMessages"
    placeholder="E.g. www.zillow.com/profile/Social%20Wonder"
    @propWasUpdated="zillowLinkModel = $event"
  />
</template>

<script>
import SmbInput from "@/components/smb-client/SmbInput.vue";

export default {
  name: "ZillowLink",
  components: { SmbInput },
  props: {
    zillowLink: {
      type: String,
      default: "",
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    zillowLinkModel: {
      get() {
        return this.zillowLink;
      },
      set(value) {
        this.$emit("propWasUpdated", value);
      },
    },
  },
};
</script>

<style scoped></style>
