<template>
  <div class="onboarding-step-header">
    <div class="onboarding-step-header__title" v-if="title">
      <span v-if="isOptional">(Optional)</span> {{ title }}
    </div>
    <div class="onboarding-step-header__description" v-if="description">
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SmbOnboardingStepHeader",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.onboarding-step-header {
  width: 100%;
  max-width: 1158px;
  margin-top: 45px;
  &__title {
    font-weight: 600;
    font-size: 60px;
    line-height: 110%;
    text-align: center;
    color: #92c4ff;
    @media screen and (max-width: 700px) {
      font-size: 40px;
    }
    span {
      font-size: 40px;
      color: #ffffff;
      margin-right: 10px;
      @media screen and (max-width: 700px) {
        font-size: 30px;
      }
    }
  }
  &__description {
    text-align: center;
    font-weight: 500;
    font-size: 19px;
    line-height: 130%;
    color: #a2a2a2;
    margin-top: 29px;
  }
}
</style>
