<template>
  <div class="wrapper">
    <div v-if="!nextStep" class="loading-step">
      <div class="loading-step__top-logo">
        <img src="@/assets/img/lettersLogo.svg" alt="" />
      </div>
      <div class="loading-step__center-logo">
        <img src="@/assets/img/logo3.svg" alt="" />
      </div>
      <div class="loading-step__info">
        <span class="text">{{ currentStep }}</span>
        <div class="loading-step__progressbar">
          <span class="loading-step__progressbar-val" v-if="stepValue < 99"
            >{{ stepValue }}%</span
          >
          <span v-else class="loading-step__progressbar-loading"
            ><v-progress-circular indeterminate></v-progress-circular
          ></span>
          <div class="progress-bar-container">
            <div class="progress-bar-child progress"></div>
            <div
              class="progress-bar-child shrinker"
              :style="{ width: 100 - stepValue + '%' }"
            ></div>
          </div>
        </div>
        <div class="loading-step__info-error" v-if="isError">
          Something went wrong
        </div>
      </div>
    </div>
    <div v-else class="ready-step">
      <div class="ready-step__top-logo">
        <img src="@/assets/img/lettersLogo.svg" alt="" />
      </div>
      <div class="ready-step__content">
        <div class="ready-step__content-text">
          <div class="title">
            Your first {{ pendingPosts.length }} social media posts are ready
            for your review
          </div>
          <router-link
            class="btn"
            :to="{
              name: 'smb-feedback',
              params: { id: getCurrentSmbCustomer.id },
            }"
            >Review content</router-link
          >
        </div>
        <div class="preview">
          <div class="preview__container">
            <div class="preview__container-top">
              <div
                class="preview__container-user-name"
                v-if="getCurrentSmbCustomer.name"
              >
                {{ getCurrentSmbCustomer.name }}
              </div>
              <img src="@/assets/img/IGpreview.jpg" alt="" />
            </div>
            <div class="preview__container-content">
              <div class="item" v-for="post in pendingPosts" :key="post.id">
                <img :src="post.media[0].mediaUrl" alt="" />
              </div>
              <div
                class="item"
                v-for="item in 9 - pendingPosts.length"
                :key="item"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LogRocket from "logrocket";

export default {
  name: "OnboardingFinalStep",
  data: () => ({
    loadingSteps: [
      { text: "Creating your marketing strategy", percent: 5 },
      { text: "Building your content calendar", percent: 20 },
      { text: "Searching the web for information and trends", percent: 43 },
      { text: "Generating the captions and hashtags", percent: 65 },
      { text: "Generating the visuals", percent: 81 },
    ],
    stepValue: 0,
    timout: 5000,
    percentTimout: 250,
    duration: 50,
    step: 1,
    percent: 1,
    currentStep: "",
    interval: null,
    isError: false,
    nextStep: false,
    intervalForPending: null,
    pendingPosts: [],
    isEveryPostHaveMediaUrl: false,
    intervalPercentage: null,
  }),
  props: {
    formForCreateContent: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
  },
  async created() {
    this.currentStep = this.loadingSteps[0].text;
    this.stepValue = 0;
    this.changeLoadingStep();
    await this.deleteBusinessAssets();
    await this.getPostsByInterval();
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
      clearInterval(this.intervalPercentage);
    }
  },
  methods: {
    ...mapActions("smbPost", ["fetchSmbPosts"]),
    ...mapActions("clientSelection", ["updateCustomer"]),
    ...mapActions("recommendations", ["createPostFromRecommendation"]),
    ...mapActions("smbClientSelection", ["fetchSmbClientInfo"]),
    changeLoadingStep() {
      this.step = 1;
      this.percent = 1;
      this.intervalFunction();
    },

    intervalFunction() {
      this.interval = setInterval(async () => {
        if (this.step < 5) {
          if (this.pendingPosts.length >= 3 && this.isEveryPostHaveMediaUrl) {
            clearInterval(this.interval);
            clearInterval(this.intervalPercentage);
            this.intervalFunction(this.interval);
            // this.timout = 3000;
            // this.percentTimout = 150;
          }
          this.currentStep = this.loadingSteps[this.step].text;
          this.step++;
        } else {
          await this.fetchSmbClientInfo(this.$route.params.id);
          if (this.pendingPosts.length >= 3 && this.isEveryPostHaveMediaUrl) {
            this.nextStep = true;
            LogRocket.track(
              `onboarding_completed_successsfully_${this.pendingPosts.length}`
            );
            clearInterval(this.interval);
          }
        }
      }, this.timout);
      this.intervalPercentage = setInterval(() => {
        if (this.percent < 100) {
          this.stepValue = this.percent;
          this.percent++;
        }
      }, this.percentTimout);
    },

    async getPosts() {
      try {
        const data = await this.fetchSmbPosts({
          routeName: "pre-approved",
          customerId: this.getCurrentSmbCustomer.id,
          limit: 100,
        });
        return data.filter((item) => item.type === "post");
      } catch (e) {
        this.isLoading = false;
        console.log(e);
      }
    },
    getPostsByInterval() {
      this.intervalForPending = setInterval(async () => {
        let posts = await this.getPosts();

        const length = posts.filter(
          (post, index) => index < 6 && post.media.length
        ).length;

        this.isEveryPostHaveMediaUrl = length >= 3;

        this.pendingPosts = posts.filter(
          (post, index) => index < 6 && post.media.length
        );

        if (
          (posts.length > 5 || this.nextStep) &&
          this.isEveryPostHaveMediaUrl
        ) {
          clearInterval(this.intervalForPending);
        }
      }, 2500);
    },
    async deleteBusinessAssets() {
      try {
        const matchAssets = this.getCurrentSmbCustomer.business_assets.includes(
          this.formForCreateContent.brand_logo
        );
        if (matchAssets) {
          const params = {
            customerId: this.getCurrentSmbCustomer.id,
            params: {
              business_assets:
                this.getCurrentSmbCustomer.business_assets.filter(
                  (item) => item !== this.formForCreateContent.brand_logo
                ),
            },
          };
          await this.updateCustomer(params);
          await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
        }
      } catch (e) {
        throw e;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #92c4ff;
  .loading-step {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding: 96px 15px 140px 15px;
    @media screen and (max-width: 760px) {
      padding: 37px 15px 120px 15px;
    }
    &__top-logo {
      margin-bottom: 40px;
    }
    &__info {
      display: flex;
      flex-direction: column;
      width: 100%;
      .text {
        font-weight: 600;
        font-size: 60px;
        line-height: 110%;
        margin-bottom: 56px;
        text-align: center;
        @media screen and (max-width: 760px) {
          font-weight: 600;
          font-size: 32px;
          line-height: 110%;
        }
      }
      &-error {
        font-weight: 600;
        font-size: 60px;
        line-height: 110%;
        color: red;
        text-align: center;
        @media screen and (max-width: 760px) {
          font-weight: 600;
          font-size: 32px;
          line-height: 110%;
          text-align: center;
        }
      }
    }
    &__progressbar {
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
      span {
        height: 35px;
        display: block;
        text-align: center;
        margin-bottom: 5px;
        font-weight: 700;
      }
    }
  }
  .ready-step {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding: 96px 15px 0 15px;
    @media screen and (max-width: 760px) {
      padding: 37px 15px 0 15px;
    }
    &__top-logo {
      margin-bottom: 100px;
    }
    &__content {
      width: 100%;
      max-width: 1250px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex: 1;
      @media screen and (max-width: 700px) {
        flex-direction: column-reverse;
        align-items: center;
      }
      &-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        max-width: 620px;
        margin-right: 30px;
        @media screen and (max-width: 700px) {
          margin-right: 0;
          align-items: center;
          padding-bottom: 40px;
        }
      }
      .title {
        font-weight: 600;
        font-size: 60px;
        line-height: 110%;
        color: #1d1d1b;
        margin-bottom: 59px;
        @media screen and (max-width: 1020px) {
          font-size: 36px;
          line-height: 110%;
        }
        @media screen and (max-width: 700px) {
          text-align: center;
          margin-bottom: 30px;
        }
      }
      .btn {
        height: 55px;
        background: #1d1d1b;
        color: #fff;
        text-decoration: none;
        font-weight: 500;
        font-size: 36px;
        line-height: 150%;
        cursor: pointer;
        padding: 0 20px;
        border-radius: 0;
      }
    }
    .preview {
      display: flex;
      max-width: 483px;
      overflow: hidden;
      @media screen and (max-width: 1020px) {
        max-width: 300px;
      }
      @media screen and (max-width: 700px) {
        margin-bottom: 40px;
      }
      &__container {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        margin-bottom: -65px;
        &-top {
          width: 100%;
          display: inline-flex;
          position: relative;
          img {
            width: 100%;
          }
        }
        &-content {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          .item {
            display: inline-flex;
            aspect-ratio: 1 / 1;
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
            background: linear-gradient(0.25turn, #d7e1ea, #c5d3e0);
            &:nth-child(3n) {
              border-right: none;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        &-user-name {
          position: absolute;
          background: #fff;
          text-transform: uppercase;
          left: 50%;
          top: 40px;
          padding: 5px;
          min-width: 150px;
          transform: translateX(-50%);
          font-weight: 700;
          text-align: center;
          font-size: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 290px;
          @media screen and (max-width: 1020px) {
            top: 23px;
            font-size: 13px;
            max-width: 180px;
          }
        }
      }
    }
  }
}

.progress-bar-container {
  width: 100%;
  height: 60px;
  margin: 0 auto;
  position: relative;
  border-radius: 35px;
  overflow: hidden;
}

.progress-bar-child {
  width: 100%;
  height: 100%;
}

.progress {
  color: white;
  text-align: center;
  line-height: 75px;
  font-size: 35px;
  font-family: "Segoe UI";
  animation-direction: reverse;
  transition: all 0.3s ease;
  /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #ffffff 0%,
    #f5ede2 25%,
    #70c5b0 53%,
    #3771c8 67%,
    #1d3427 81%,
    #1d1d1b 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #ffffff 0%,
    #f5ede2 25%,
    #70c5b0 53%,
    #3771c8 67%,
    #1d3427 81%,
    #1d1d1b 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #ffffff 0%,
    #f5ede2 25%,
    #70c5b0 53%,
    #3771c8 67%,
    #1d3427 81%,
    #1d1d1b 100%
  );
}

.shrinker {
  background-color: black;
  position: absolute;
  transition: all 0.3s ease;
  top: 0;
  right: 0;
  width: 100%;
}
</style>
