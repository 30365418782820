var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[(!_vm.nextStep)?_c('div',{staticClass:"loading-step"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"loading-step__info"},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.currentStep))]),_c('div',{staticClass:"loading-step__progressbar"},[(_vm.stepValue < 99)?_c('span',{staticClass:"loading-step__progressbar-val"},[_vm._v(_vm._s(_vm.stepValue)+"%")]):_c('span',{staticClass:"loading-step__progressbar-loading"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1),_c('div',{staticClass:"progress-bar-container"},[_c('div',{staticClass:"progress-bar-child progress"}),_c('div',{staticClass:"progress-bar-child shrinker",style:({ width: 100 - _vm.stepValue + '%' })})])]),(_vm.isError)?_c('div',{staticClass:"loading-step__info-error"},[_vm._v(" Something went wrong ")]):_vm._e()])]):_c('div',{staticClass:"ready-step"},[_vm._m(2),_c('div',{staticClass:"ready-step__content"},[_c('div',{staticClass:"ready-step__content-text"},[_c('div',{staticClass:"title"},[_vm._v(" Your first "+_vm._s(_vm.pendingPosts.length)+" social media posts are ready for your review ")]),_c('router-link',{staticClass:"btn",attrs:{"to":{
            name: 'smb-feedback',
            params: { id: _vm.getCurrentSmbCustomer.id },
          }}},[_vm._v("Review content")])],1),_c('div',{staticClass:"preview"},[_c('div',{staticClass:"preview__container"},[_c('div',{staticClass:"preview__container-top"},[(_vm.getCurrentSmbCustomer.name)?_c('div',{staticClass:"preview__container-user-name"},[_vm._v(" "+_vm._s(_vm.getCurrentSmbCustomer.name)+" ")]):_vm._e(),_c('img',{attrs:{"src":require("@/assets/img/IGpreview.jpg"),"alt":""}})]),_c('div',{staticClass:"preview__container-content"},[_vm._l((_vm.pendingPosts),function(post){return _c('div',{key:post.id,staticClass:"item"},[_c('img',{attrs:{"src":post.media[0].mediaUrl,"alt":""}})])}),_vm._l((9 - _vm.pendingPosts.length),function(item){return _c('div',{key:item,staticClass:"item"})})],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loading-step__top-logo"},[_c('img',{attrs:{"src":require("@/assets/img/lettersLogo.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loading-step__center-logo"},[_c('img',{attrs:{"src":require("@/assets/img/logo3.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ready-step__top-logo"},[_c('img',{attrs:{"src":require("@/assets/img/lettersLogo.svg"),"alt":""}})])
}]

export { render, staticRenderFns }