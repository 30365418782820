<template>
  <div class="colors">
    <div
      :class="[
        'colors__tabs',
        { 'gray-mode': isLeaveSuggested && isBackSuggested },
      ]"
    >
      <div
        :class="['colors__tabs-item', { active: item.id === activeTabId }]"
        @click="setActiveTab(item)"
        v-for="item of tabs"
        :key="item.id"
      >
        {{ item.name }}
      </div>
    </div>
    <PresetColors
      v-if="activeTabId === 1"
      :key="activeTabId"
      :presets="suggestedColors"
      :sliderSettings="sliderSuggestedColors"
      @setPresetColors="colorsListModel = $event.colors"
    />
    <SmbColorPicker
      v-if="activeTabId === 2"
      :key="activeTabId"
      :colors-list="colorsListModel"
      :current-color="currentColor"
      :current-index="currentIndex"
      :triggeredAddColorButton="triggeredAddColorButton"
      :isShowButtonsSection="isShowButtonsSection"
      :isCanDeleteDefaultColors="true"
      @colorListChanged="colorsListModel = $event"
      @currentIndexChanged="currentIndex = $event"
      @currentColorChanged="currentColor = $event"
    />
    <PresetColors
      v-if="activeTabId === 3"
      :key="activeTabId"
      :presets="brokerageColors"
      :isBrokerages="true"
      :sliderSettings="sliderBrokerageColors"
      @setPresetColors="colorsListModel = $event.colors"
    />
  </div>
</template>

<script>
import SmbColorPicker from "@/components/smb-client/SmbColorPicker.vue";
import PresetColors from "@/views/smb-client/pages/onboarding/steps/PresetColors.vue";
import { BROKERAGE_COLORS, SUGGESTED_COLORS } from "@/constants/smb-client";

export default {
  name: "Colors",
  data: () => ({
    currentColor: null,
    currentIndex: null,
    isLeaveSuggested: false,
    isBackSuggested: false,
    suggestedColors: SUGGESTED_COLORS,
    brokerageColors: BROKERAGE_COLORS,
    tabs: [
      {
        id: 1,
        name: "Suggested colors",
      },
      {
        id: 2,
        name: "Your own colors",
      },
      {
        id: 3,
        name: "Brokerage colors",
      },
    ],
    sliderSuggestedColors: {
      dots: false,
      slidesToShow: 6,
      slidesToScroll: 2,
      arrows: true,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 450,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    },
    sliderBrokerageColors: {
      dots: false,
      slidesToShow: 10,
      slidesToScroll: 0,
      arrows: false,
      responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            arrows: true,
          },
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows: true,
          },
        },
        {
          breakpoint: 450,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
          },
        },
      ],
    },
    activeTabId: 1,
  }),
  components: { SmbColorPicker, PresetColors },
  props: {
    triggeredAddColorButton: {
      type: [Date, Object],
      default: null,
    },
    colorsList: {
      type: Array,
      default: () => [],
    },
    isShowButtonsSection: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    colorsListModel: {
      get() {
        return this.colorsList;
      },
      set(value) {
        this.$emit("colorsWereUpdated", value);
      },
    },
  },
  methods: {
    setActiveTab(item) {
      this.activeTabId = null;
      this.activeTabId = item.id;
      if (item.id !== 1) {
        this.isLeaveSuggested = true;
      }
      if (item.id === 1 && this.isLeaveSuggested) {
        this.isBackSuggested = true;
      }
      item.id === 2
        ? this.$emit("ownColorsMode", true)
        : this.$emit("ownColorsMode", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.colors {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__tabs {
    display: inline-flex;
    align-items: center;

    &-item {
      color: #1d1d1b;
      text-align: center;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      border-radius: 13px;
      border: 1px solid #000;
      padding: 25px 10px;
      margin: 0 15px;
      cursor: pointer;
      @media screen and (max-width: 768px) {
        font-size: 16px;
        padding: 20px 10px;
        margin: 0 5px;
      }
      &.active {
        border: 1px solid #92c4ff;
        box-shadow: 0 0 0 3px #92c4ff;
      }
    }
    &.gray-mode {
      .colors__tabs-item {
        font-weight: 500;
        opacity: 0.5;
        &.active {
          opacity: 1;
          font-weight: 700;
        }
      }
    }
  }

  &__content {
    width: 100%;
  }
}
</style>
