<template>
  <div :class="['color-slider', { brokerages: isBrokerages }]">
    <VueSlickCarousel
      v-if="presets.length"
      v-bind="sliderSettings"
      ref="carousel"
    >
      <div class="color-slider__item" v-for="item of presets" :key="item.id">
        <div
          :class="[
            'color-slider__item-wrapper',
            {
              active:
                selectedColorSetId !== null && selectedColorSetId === item.id,
            },
            {
              'no-active':
                selectedColorSetId !== null && selectedColorSetId !== item.id,
            },
          ]"
        >
          <div
            class="color-slider__item-color"
            v-for="(color, index) of item.colors"
            :key="index"
            :style="{ backgroundColor: color }"
            @click="setColorPreset(item)"
          ></div>
        </div>
      </div>
      <template #nextArrow>
        <div class="custom-arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="16"
            viewBox="0 0 11 16"
            fill="none"
          >
            <path
              d="M2.17972 0.316623L10.7212 7.40897C10.8226 7.4934 10.8942 7.58487 10.9361 7.68338C10.9787 7.78188 11 7.88742 11 8C11 8.11258 10.9787 8.21812 10.9361 8.31662C10.8942 8.41513 10.8226 8.5066 10.7212 8.59103L2.17972 15.7045C1.94316 15.9015 1.64747 16 1.29263 16C0.937788 16 0.63364 15.8945 0.380184 15.6834C0.126728 15.4723 0 15.226 0 14.9446C0 14.6631 0.126728 14.4169 0.380184 14.2058L7.8318 8L0.380184 1.7942C0.143625 1.59719 0.0253458 1.35458 0.0253458 1.06639C0.0253458 0.777626 0.152074 0.527704 0.40553 0.316623C0.658986 0.105541 0.954685 0 1.29263 0C1.63057 0 1.92627 0.105541 2.17972 0.316623Z"
              fill="#585757"
            ></path>
          </svg>
        </div>
      </template>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  name: "PresetColors",
  props: {
    presets: {
      type: Array,
      default: () => [],
    },
    sliderSettings: {
      type: Object,
      default: () => {},
    },
    isBrokerages: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedColorSet: null,
    selectedColorSetId: null,
  }),
  created() {},
  components: {
    VueSlickCarousel,
  },
  methods: {
    setColorPreset(item) {
      if (this.selectedColorSetId === item.id) {
        this.selectedColorSetId = null;
        this.$emit("setPresetColors", { id: null, colors: [] });
      } else {
        this.selectedColorSetId = item.id;
        this.$emit("setPresetColors", item);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.color-slider {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  padding: 21px 0;
  position: relative;
  @media screen and (max-width: 1100px) {
    padding: 21px 20px 21px 0;
  }
  &.brokerages {
    max-width: 1212px;
  }
  &__item {
    width: 100%;
    padding: 4px 9px;
  }
  &__item-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid #000;
    overflow: hidden;
    &.active {
      border: 1px solid #92c4ff;
      box-shadow: 0 0 0 3px #92c4ff;
    }
    &.no-active {
      opacity: 0.5;
    }
  }
  &__item-color {
    height: 35px;
  }

  .custom-arrow {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    &:before {
      display: none;
    }
    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &.slick-prev {
      left: -45px;
      @media screen and (max-width: 1100px) {
        left: -31px;
      }
    }
    &.slick-next {
      right: -45px;
      @media screen and (max-width: 1100px) {
        right: -31px;
      }
    }
  }
}
</style>
