<template>
  <div class="wrapper" data-app>
    <v-stepper v-model="currentStep">
      <v-stepper-header v-if="currentStep !== getStepNumber(8)">
        <div class="steps-list">
          <div
            class="steps-list__item"
            v-for="item in getStepNumber(7)"
            :key="item"
            :class="{ active: item === currentStep }"
          >
            {{ item }}
          </div>
        </div>
        <SmbOnboardingStepHeader
          :title="getTitleDescriptionAndIsOptional.title"
          :description="getTitleDescriptionAndIsOptional.description"
          :isOptional="getTitleDescriptionAndIsOptional.isOptional"
        />
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          v-if="$route.params.isFromNewOnboardingPage"
          class="step"
          :step="getStepNumber(1)"
        >
          <div class="step__content">
            <div class="content-container">
              <v-form v-model="isBaseFormValid" ref="form" lazy-validation>
                <BaseForm
                  :email="form.email"
                  :phone="form.phone"
                  :first-name="form.fistName"
                  :last-name="form.lastName"
                  @emailWasUpdated="form.email = $event"
                  @phoneWasUpdated="form.phone = $event"
                  @firstNameWasUpdated="form.fistName = $event"
                  @lastNameWasUpdated="form.lastName = $event"
                />
              </v-form>
            </div>
          </div>

          <div class="step__controls">
            <SmbActionBtn
              width="80%"
              text="Next"
              :isDisabled="
                form.fistName &&
                form.email &&
                form.phone &&
                form.lastName &&
                !isBaseFormValid
              "
              emitName="next"
              @next="
                changeStepHandler(currentStep + 1, true, {
                  mail: form.email,
                  name: form.fistName,
                  last_name: form.lastName,
                  primary_phone: '1' + form.phone,
                })
              "
            />
          </div>
        </v-stepper-content>
        <v-stepper-content class="step" :step="getStepNumber(2)">
          <div class="step__content">
            <div class="content-container">
              <Location
                :area="form.area"
                @propWasUpdated="form.area = $event"
              />
            </div>
          </div>

          <div class="step__controls">
            <SmbActionBtn
              v-if="$route.params.isFromNewOnboardingPage"
              class="arrow-button"
              text="Back"
              emitName="back"
              isUseIcon
              iconName="mdi-arrow-left"
              width="20%"
              @back="changeStepHandler(currentStep - 1)"
            />
            <SmbActionBtn
              width="80%"
              text="Next"
              :isDisabled="!form.area"
              emitName="next"
              @next="clickedNextOrSkipOnTheLocationStep"
            />
          </div>
        </v-stepper-content>

        <v-stepper-content class="step" :step="getStepNumber(3)">
          <div class="step__content">
            <div class="content-container colors">
              <Colors
                :triggeredAddColorButton="timeWhenAddColorBtnClicked"
                :colors-list="form.colorsList"
                @colorsWereUpdated="form.colorsList = $event"
                @ownColorsMode="isOwnColorsMode = $event"
                :isShowButtonsSection="false"
              />
            </div>
          </div>

          <div class="step__controls color">
            <SmbActionBtn
              class="arrow-button"
              text="Back"
              emitName="back"
              isUseIcon
              iconName="mdi-arrow-left"
              width="20%"
              @back="changeStepHandler(currentStep - 1)"
            />
            <SmbActionBtn
              width="80%"
              class="add-color"
              v-if="isOwnColorsMode"
              text="Add color"
              :is-disabled="form.colorsList.length >= 6"
              emitName="addColor"
              @addColor="addColorHandler"
            />
            <SmbActionBtn
              width="80%"
              :isDisabled="form.colorsList.length < 1"
              text="Next"
              emitName="next"
              @next="setBrandsColors()"
            />
          </div>
        </v-stepper-content>

        <v-stepper-content class="step" :step="getStepNumber(4)">
          <div class="step__content">
            <div class="content-container logo">
              <LoadingLogo
                :is-upload="isUploadLogo"
                :attachedLogo="selectedAssetsLogo"
                @newLogo="updateUserLogo($event)"
                @setNewLogo="setAssetsLogo($event)"
                @fileAttached="isAttachedLogoHandler($event)"
                @logoType="logoType = $event"
              />
            </div>
          </div>

          <div class="step__controls">
            <SmbActionBtn
              class="arrow-button"
              text="Back"
              emitName="back"
              isUseIcon
              iconName="mdi-arrow-left"
              width="20%"
              @back="changeStepHandler(currentStep - 1)"
            />
            <SmbActionBtn
              width="80%"
              :text="
                (logoIsAttached && logoType === '2') ||
                (selectedAssetsLogo &&
                  selectedAssetsLogo !== form.brand_logo &&
                  logoType === '1')
                  ? 'Next'
                  : 'Skip for now'
              "
              emitName="next"
              @next="uploadLogoHandler()"
            />
          </div>
        </v-stepper-content>

        <v-stepper-content class="step" :step="getStepNumber(5)">
          <div class="step__content">
            <div class="content-container">
              <zillow-link
                :zillow-link="form.zillowLink"
                @propWasUpdated="form.zillowLink = $event"
                :errorMessages="
                  form.zillowLink && !isZillowProfileLink(form.zillowLink)
                    ? [
                        'Value must be valid like www.zillow.com/profile/Social%20Wonder',
                      ]
                    : []
                "
              />
            </div>
          </div>

          <div class="step__controls">
            <SmbActionBtn
              class="arrow-button"
              text="Back"
              emitName="back"
              isUseIcon
              iconName="mdi-arrow-left"
              width="20%"
              @back="changeStepHandler(currentStep - 1)"
            />
            <SmbActionBtn
              width="80%"
              :text="
                form.zillowLink && isZillowProfileLink(form.zillowLink)
                  ? 'Next'
                  : 'Skip for now'
              "
              emitName="next"
              @next="
                changeStepHandler(
                  currentStep + 1,
                  isZillowProfileLink(form.zillowLink) &&
                    form.zillowLink !== getCurrentSmbCustomer.zillow_page,
                  {
                    zillowPageSaas: form.zillowLink,
                  }
                )
              "
            />
          </div>
        </v-stepper-content>
        <v-stepper-content class="step" :step="getStepNumber(6)">
          <div class="step__content">
            <Brands
              :brands="brandsList"
              :selected-brands="form.brands"
              @clickedOnBrand="clickedOnBrandHandler($event)"
            />
          </div>

          <div class="step__controls">
            <SmbActionBtn
              class="arrow-button"
              text="Back"
              emitName="back"
              isUseIcon
              iconName="mdi-arrow-left"
              width="20%"
              @back="changeStepHandler(currentStep - 1)"
            />
            <SmbActionBtn
              width="80%"
              text="Next"
              :is-disabled="!form.brands.length"
              emitName="next"
              @next="
                changeStepHandler(currentStep + 1, true, {
                  brand_values: form.brands,
                })
              "
            />
          </div>
        </v-stepper-content>
        <v-stepper-content class="step" :step="getStepNumber(7)">
          <div class="step__content">
            <target-customers
              :target-customers-list="targetCustomersList"
              :selected-target-customers="form.targetCustomers"
              @clickedOnTargetCustomer="clickedOnTargetCustomerHandler($event)"
            />
          </div>

          <div class="step__controls">
            <SmbActionBtn
              class="arrow-button"
              text="Back"
              emitName="back"
              isUseIcon
              iconName="mdi-arrow-left"
              width="20%"
              @back="changeStepHandler(currentStep - 1)"
            />
            <SmbActionBtn
              width="80%"
              text="Next"
              :is-disabled="!form.targetCustomers.length"
              @next="
                changeStepHandler(currentStep + 1, true, {
                  target_customers: form.targetCustomers,
                })
              "
              emitName="next"
            />
          </div>
        </v-stepper-content>

        <OnboardingFinalStep
          v-if="currentStep === getStepNumber(8)"
          :formForCreateContent="form"
        />
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SmbOnboardingStepHeader from "@/components/smb-client/SmbOnboardingStepHeader";
import SmbActionBtn from "@/components/smb-client/SmbActionBtn";
import OnboardingFinalStep from "@/views/smb-client/pages/onboarding/steps/OnboardingFinalStep.vue";
import Location from "@/views/smb-client/pages/onboarding/steps/Location.vue";
import Colors from "@/views/smb-client/pages/onboarding/steps/Colors.vue";
import ZillowLink from "@/views/smb-client/pages/onboarding/steps/ZillowLink.vue";
import Brands from "@/views/smb-client/pages/onboarding/steps/Brands.vue";
import TargetCustomers from "@/views/smb-client/pages/onboarding/steps/TargetCustomers.vue";
import LoadingLogo from "@/views/smb-client/pages/onboarding/steps/LoadingLogo.vue";
import BaseForm from "@/views/smb-client/pages/onboarding/steps/BaseForm.vue";

export default {
  name: "Index",
  components: {
    BaseForm,
    TargetCustomers,
    Brands,
    Location,
    Colors,
    ZillowLink,
    SmbOnboardingStepHeader,
    SmbActionBtn,
    OnboardingFinalStep,
    LoadingLogo,
  },
  data() {
    return {
      currentStep: 1,
      timeWhenAddColorBtnClicked: null,
      brandsList: [
        "Friendly",
        "Relaxed",
        "Professional",
        "Bold",
        "Persuasive",
        "Luxury",
        "Informative",
        "Witty",
      ],
      targetCustomersList: [
        "First-Time Homebuyer/Sellers",
        "Luxury Buyers/Sellers",
        "Transitioning Homeowners",
        "Commercial Property Investors",
        "Property Investors",
        "Business Owners Seeking Property",
        "Family-Centric Homebuyers/Sellers",
        "Real Estate Developers",
      ],
      logoIsAttached: false,
      isOwnColorsMode: false,
      isUploadLogo: false,
      selectedAssetsLogo: null,
      logoType: null,
      form: {
        area: "",
        colorsList: [],
        brand_logo: "",
        zillowLink: "",
        website: "",
        brands: [],
        targetCustomers: [],
        phone: "",
        email: "",
        lastName: "",
        fistName: "",
      },
      isBaseFormValid: false,
    };
  },
  created() {
    this.calculateVh();
    window.addEventListener("resize", this.calculateVh);
    window.addEventListener("orientationchange", this.calculateVh);
    if (this.$route.params && this.$route.params.isFromNewOnboardingPage) {
      this.currentStep = 2;
    }

    const {
      website,
      area,
      brand_color_palettes,
      brand_logo,
      zillow_page,
      brand_values,
      target_customers,
      mail,
      primary_phone,
      name,
      last_name,
    } = this.getCurrentSmbCustomer;
    this.form.website = website;
    this.form.area = area;

    if (
      brand_color_palettes &&
      brand_color_palettes.length &&
      Array.isArray(brand_color_palettes)
    ) {
      this.form.colorsList = brand_color_palettes[0];
    }

    if (primary_phone && this.isPhoneNumberStartWithOne(primary_phone)) {
      this.form.phone = this.deleteFromStringByIndex(primary_phone, 1);
    }

    this.form.zillowLink = zillow_page;
    this.form.brand_logo = brand_logo;
    this.form.brands = brand_values;
    this.form.targetCustomers = target_customers;
    this.form.email = mail;
    this.form.fistName = name;
    this.form.lastName = last_name;
  },
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    getTitleDescriptionAndIsOptional() {
      switch (this.getCurrentStep) {
        case 1:
          return {
            title: "Start your free trial",
            description:
              "Enter your email, we'll check if you have an account, and help create one if you don't.",
            isOptional: false,
          };
        case 2:
          return {
            title: "In what areas do you operate?",
            description:
              "It help us fine tune the content based on your location. Press Enter to add more locations.",
            isOptional: false,
          };
        case 3:
          return {
            title: "What are your brand colors?",
            description:
              "Add your own brand colors or choose from the options below. You can always change your brand colors later on the Settings page.",
            isOptional: false,
          };
        case 4:
          return {
            title: "Do you have a logo?",
            description: "You can add it now or add it later  in the Settings",
            isOptional: true,
          };
        case 5:
          return {
            title: "Do you have a Zillow page?",
            description:
              "This will allow us to get active and past listings as well as testimonials",
            isOptional: true,
          };
        case 6:
          return {
            title: "Pick up to 3 values that are key to your brand\n",
            description: "",
            isOptional: false,
          };
        case 7:
          return {
            title: "Who are your target customers?",
            description: "Choose all that applies",
            isOptional: false,
          };
        default:
          return {
            title: "",
            description: "",
            isOptional: false,
          };
      }
    },
    getCurrentStep() {
      return this.$route.params.isFromNewOnboardingPage
        ? this.currentStep
        : this.currentStep + 1;
    },
  },
  methods: {
    ...mapActions("clientSelection", ["updateCustomer"]),
    ...mapActions("recommendations", ["createPostFromRecommendation"]),
    deleteFromStringByIndex(string, indexForRemove) {
      return string.substring(indexForRemove);
    },
    isPhoneNumberStartWithOne(phoneNumber) {
      return phoneNumber.startsWith("1");
    },
    calculateVh() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", vh + "px");
    },
    addColorHandler() {
      this.timeWhenAddColorBtnClicked = new Date();
    },
    isZillowProfileLink(text) {
      const expression =
        /(www\.)?zillow\.[a-zA-Z0-9()]{1,6}\b\/profile\/([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi;
      const regex = new RegExp(expression);
      return !!text.match(regex);
    },
    isLink(text) {
      const expression =
        /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+(\/[^\s]*)?$/;
      const regex = new RegExp(expression);
      return regex.test(text);
    },
    clickedOnBrandHandler(clickedBrand) {
      this.form.brands =
        !this.form.brands.includes(clickedBrand) && this.form.brands.length < 3
          ? [...this.form.brands, ...[clickedBrand]]
          : this.form.brands.filter(
              (selectedBrand) => selectedBrand !== clickedBrand
            );
    },
    clickedOnTargetCustomerHandler(clickedTargetCustomer) {
      this.form.targetCustomers = !this.form.targetCustomers.includes(
        clickedTargetCustomer
      )
        ? [...this.form.targetCustomers, ...[clickedTargetCustomer]]
        : this.form.targetCustomers.filter(
            (selectedTargetCustomer) =>
              selectedTargetCustomer !== clickedTargetCustomer
          );
    },
    setBrandsColors() {
      if (this.form.colorsList.length === 1) {
        if (
          this.form.colorsList[0] === "#FFFFFF" ||
          this.form.colorsList[0] === "#000000"
        ) {
          if (this.form.colorsList[0] === "#000000") {
            this.form.colorsList.push("#FFFFFF");
          } else {
            this.form.colorsList.push("#000000");
          }
        } else {
          this.form.colorsList.push("#000000");
          this.form.colorsList.push("#FFFFFF");
        }
        this.changeStepHandler(this.currentStep + 1, true, {
          brand_color_palettes: [this.form.colorsList],
        });
      } else {
        this.changeStepHandler(this.currentStep + 1, true, {
          brand_color_palettes: [this.form.colorsList],
        });
      }
    },
    isAttachedLogoHandler(e) {
      if (e) {
        this.logoIsAttached = true;
        this.isUploadLogo = false;
      } else {
        this.logoIsAttached = false;
        this.isUploadLogo = false;
      }
    },
    async uploadLogoHandler() {
      if (this.logoIsAttached && this.logoType === "2") {
        this.isUploadLogo = true;
      } else if (this.selectedAssetsLogo && this.logoType === "1") {
        await this.changeStepHandler(this.currentStep + 1, true, {
          brand_logo: this.selectedAssetsLogo,
        });
        this.logoIsAttached = false;
        this.form.brand_logo = this.selectedAssetsLogo;
      } else {
        this.currentStep = this.currentStep + 1;
      }
    },
    async updateUserLogo(logo) {
      if (this.logoType === "2") {
        await this.changeStepHandler(this.currentStep + 1, true, {
          brand_logo: logo,
        });
        this.logoIsAttached = false;
        this.selectedAssetsLogo = null;
        this.form.brand_logo = logo;
      }
    },

    setAssetsLogo(logo) {
      this.selectedAssetsLogo = logo;
    },
    async updateCustomerHandler(params) {
      try {
        const finallyParams = {
          customerId: this.getCurrentSmbCustomer.id,
          params,
        };

        await this.updateCustomer(finallyParams);
      } catch (e) {
        throw e;
      }
    },
    async changeStepHandler(
      newStepNumber,
      isNeedToUpdateCustomer = false,
      params = {}
    ) {
      this.currentStep = newStepNumber;

      if (isNeedToUpdateCustomer) {
        try {
          await this.updateCustomerHandler(params);
        } catch (e) {
          throw e;
        }
      }
    },
    async clickedNextOrSkipOnTheLocationStep() {
      try {
        await this.changeStepHandler(this.currentStep + 1, true, {
          areaSaas: this.form.area,
        });

        this.createPostFromRecommendation({
          customerId: this.getCurrentSmbCustomer.id,
          task: "generate",
        });
      } catch (e) {
        throw e;
      }
    },
    async clickedNextOrSkipOnTheWebsiteStep() {
      try {
        await this.changeStepHandler(this.currentStep + 1, true, {
          website: this.form.website,
        });

        this.createPostFromRecommendation({
          customerId: this.getCurrentSmbCustomer.id,
          task: "generate",
        });
      } catch (e) {
        throw e;
      }
    },
    getStepNumber(currentStep) {
      return this.$route.params.isFromNewOnboardingPage
        ? +currentStep
        : +currentStep - 1;
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh) * 100);
  .v-stepper {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 0;
    box-shadow: none !important;
    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: none;
      padding: 51px 15px 20px 15px;
      background: #1d1d1b;
      border-radius: 0;
      height: auto;
    }
    &__items {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 0 !important;
    }
    ::v-deep {
      .v-stepper__wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
    .step {
      &__content {
        flex: 1;
        padding: 20px 15px 30px 15px;
      }
      &__controls {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &.color {
          display: flex;
          flex-direction: column;
          .arrow-button {
            @media screen and (max-width: 920px) {
              bottom: 30px;
            }
            @media screen and (max-width: 760px) {
              bottom: 0px;
            }
          }
          @media screen and (min-width: 920px) {
            flex-direction: row;
          }
        }
        @media screen and (min-width: 760px) {
          padding-bottom: 30px;
        }
        .button-item {
          width: 100%;
          @media screen and (min-width: 760px) {
            max-width: 315px;
          }
        }
        .add-color {
          margin-right: 20px;
          @media screen and (max-width: 920px) {
            margin: 0 auto 40px auto;
            max-width: 150px;
          }
          ::v-deep {
            .btn-text {
              background: #92c4ff !important;
              color: #1d1d1b;
            }
          }
        }
        .arrow-button {
          width: 20%;
          left: 0;
          position: absolute;
          z-index: 1;
          ::v-deep {
            .v-btn__content {
              .text {
                display: none;
              }
            }
          }
          @media screen and (min-width: 760px) {
            position: absolute;
            left: 50px;
            ::v-deep {
              .btn-text {
                background: transparent !important;
                border-color: transparent !important;
                box-shadow: none !important;
                color: #1d1d1b;
                max-width: 150px;
                width: auto;
                .mdi-arrow-left {
                  margin-right: 20px;
                }
                &:after,
                &:before {
                  display: none;
                }
                .v-ripple__container {
                  display: none;
                }
              }
              .v-btn__content {
                .text {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }
  .steps-list {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &__item {
      width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      border-radius: 50%;
      background: #585757;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      margin-right: 13px;
      position: relative;
      &:after {
        content: "";
        height: 2px;
        width: 13px;
        position: absolute;
        right: -13px;
        background: #585757;
        top: 50%;
        transform: translateY(-50%);
      }
      &.active {
        background: #f5ede2;
        color: #1d1d1b;
      }
      &:last-child {
        margin-right: 0;
        &:after {
          display: none;
        }
      }
    }
  }
}
.content-container {
  width: 100%;
  max-width: 500px;
  margin: 30px auto 20px auto;
  &.colors,
  &.logo {
    max-width: 100%;
  }
  ::v-deep {
    .v-color-picker {
      margin: 0 auto;
    }
  }
}
</style>
